import * as ConfirmationDialog from '../ConfirmationDialog'
import { useConfirmationDialogContext } from '../../../contexts/confirmation-dialog-context'
import * as React from 'react'

export type GlobalConfirmationDialogProps = {
  id: string
} & Omit<
  ConfirmationDialog.ConfirmationDialogProps,
  | 'children'
  | 'onConfirm'
  | 'onCancel'
  | 'defaultOpen'
  | 'open'
  | 'onOpenChange'
>

export const GlobalConfirmationDialog = (
  props: GlobalConfirmationDialogProps
) => {
  const { id, title, body, cancelLabel, confirmLabel, contentClassName } = props

  const {
    activeConfirmationDialogId,
    openConfirmationDialog,
    closeConfirmationDialog,
    getConfirmationDialogProps,
  } = useConfirmationDialogContext()

  const open = React.useMemo(
    () => activeConfirmationDialogId === id,
    [activeConfirmationDialogId, id]
  )

  const {
    onCancel: globalOnCancel,
    onConfirm: globalOnConfirm,
    body: globalBody,
    title: globalTitle,
    cancelLabel: globalCancelLabel,
    confirmLabel: globalConfirmLabel,
  } = getConfirmationDialogProps(id)

  const onConfirm = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      globalOnConfirm?.(e)
      closeConfirmationDialog()
    },
    [globalOnConfirm]
  )

  const onCancel = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      globalOnCancel?.(e)
      closeConfirmationDialog()
    },
    [globalOnCancel]
  )

  const sharedProps = {
    onConfirm,
    onCancel,
    title: globalTitle || title,
    body: globalBody || body,
    cancelLabel: globalCancelLabel || cancelLabel,
    confirmLabel: globalConfirmLabel || confirmLabel,
    contentClassName,
  }

  React.useEffect(() => {
    if (open) openConfirmationDialog(id, sharedProps)
    else closeConfirmationDialog()
  }, [open])

  return <ConfirmationDialog.Root open={open} {...sharedProps} />
}
